import React from 'react'
import './Properties.scss'
import { Link } from "gatsby"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import GenerateLink from "../../common/link/generate-link"

import ManageBG from "../../images/property-image-2.png"

const Properties = (props) => {
    return (
        <section className='properties-section'>
            <div className='property-wrap'>
                <div className='list-property'>
                    <div className='list-wrp'>
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                            <div className='list-content'>
                                <h3>{props.column_1_sub_title}</h3>
                                <h4>{props.column_1_title}</h4>
                                <GenerateLink link={props.column_1_cta_link} class="btn">{props.column_1_cta_label}</GenerateLink>
                            </div>
                        </AnimationOnScroll>

                    </div>
                </div>
                <div className='list-property property-management' style={{ backgroundImage: `url(${props?.column_2_bg_image ? props?.column_2_bg_image?.url : ManageBG})` }}>
                    <div className='list-wrp'>
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                            <div className='list-content'>
                                <h3>{props.column_2_sub_title}</h3>
                                <h4>{props.column_2_title}</h4>
                                <GenerateLink link={props.column_2_cta_link} class="btn">{props.column_2_cta_label}</GenerateLink>
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>
            </div >
        </section >
    )
}

export default Properties